.taskbutton {
  padding: 0.8em 1.8em;
  border: 1px solid #aaaaaa;
  position: relative;
  overflow: hidden;
  background-color: transparent;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  transition: 0.3s;
  z-index: 1;
  font-family: inherit;
  color: #ffffff;
}

.taskbutton::before {
  content: "";
  width: 0;
  height: 300%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background: #fe4c1c;
  transition: 0.5s ease;
  display: block;
  z-index: -1;
}

.taskbutton:hover::before {
  width: 105%;
}

.taskbutton:hover {
  color: #ffffff;
}
.Tak_Container {
}
