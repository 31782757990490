.CurrentQRcard {
    width: 240px;
    height: 280px;
    padding: 0.5rem;
    background: rgba(198, 198, 198, 0.34);
    border-radius: 8px;
    backdrop-filter: blur(5px);
    border-bottom: 3px solid rgba(255, 255, 255, 0.440);
    border-left: 2px  rgba(255, 255, 255, 0.545) outset;
    box-shadow: -25px 20px 30px rgba(100, 99, 99, 0.28);
    overflow: hidden;
    color: white;
  }
  
 
  
  .align {
    padding: 0.4rem;
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-self: flex-start;
  }
  
  .red {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ff605c;
    box-shadow: -5px 5px 5px rgba(0, 0, 0, 0.280);
  }
  
  .yellow {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ffbd44;
    box-shadow: -5px 5px 5px rgba(0, 0, 0, 0.280);
  }
  
  .green {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #00ca4e;
    box-shadow: -5px 5px 5px rgba(0, 0, 0, 0.280);
  }
  
  .CurrentQRcard h3 {
    text-align: center;
    margin: 0.7rem;
    color: rgb(218, 244, 237);
    text-shadow: -10px 5px 10px rgba(0, 0, 0, 0.573);
  }
  
  .CurrentQRImage{
    margin-top: 14px;

    border-radius: 10px;
  }