
a{
  text-decoration: none;
}
/* Header 7 newsletter */

.footer{
    /* background-image: url(../../assets/Images/home-banner.jpg); */
    background-color: black;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
 
    padding: 25px 0px 10px 0px;

}
.footer-text{
    text-align: left;
    color: #fff;
}
.footer-text h3{
 font-size: 20px;
 font-weight: 600;
 padding: 20px 0px 10px 0px;
 border-bottom: 5px solid #ffffff;
 border-width: 3px;
 width: 50px;
}
.footer-text p{
    line-height: 25px;
}
.footer-text ul{
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.footer-text ul li{
    margin-bottom: 5px;
    color: #fcf4e8;
    font-size: 16px;
    font-weight: 400;
}
.footer-text ul li a{
 color: #fff;
}
.footer_brand{
  height: 48px;
  margin-bottom:5px ;
}
.footer-text ul li a:hover{
text-decoration:none;
}
.newsletter{
text-align: center;
}
.footer-end{
  color: #ffffff;

}
.hr_footer{
  color: #eaeaea;
}