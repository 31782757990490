.features {
    width: 100%;
    /* border-radius: 10px; */
    background-color: rgb(66, 66, 66);
    box-shadow: 0 15px 35px rgb(0 0 0 / 30%);
    height: auto;
    /* display: flex; */
    padding: 50px 20px;
    justify-content: space-around;
  }
  .features:hover {
    /* border-radius: 60px; */
    /* background-color: #ffffff; */
    box-shadow: 0 15px 35px rgb(0 0 0 / 30%);
    /* filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.5)); */
        /* -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    transform: translateY(-10px);
    transition: 0.5s; */
  }
  .feature-container {
    /* flex-basis: 30%; */
    border-radius: 5px;
    margin-top: 10px;
    
  }
  
  .feature-card p {
    color: #ffffff;
    text-align: center;
    line-height: 1.4;
    margin-bottom: 15px;
 width:100%;
  }
  .feature-card h2 {
  cursor: pointer;
  color: #fe4c1c;
 
  }
  .feature-card h2:hover {
    cursor: pointer;
    color: #000000;
   
    }
  
  .feature-container :hover{
    border-radius: 20px;
    /* background-color: #f9f9f9; */
    box-shadow: 0 15px 35px rgb(0 0 0 / 30%);
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    transform: translateY(-10px);
    transition: 0.5s;
  }
  .feature-container img {
    width: 100%;
    margin-bottom: 15px;
  }
 