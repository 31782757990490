.newsletter {
  padding: 100px 0px 80px 0px;
}
.newsletter-form {
  position: relative;
  max-width: 600px;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
}
.newsletter-form .input-newsletter {
  display: block;
  width: 100%;
  border: none;
  background-color: #eaeaea;
  color: #080e32;
  height: 50px;
  padding-left: 18px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
}
.newsletter-form .default-btn {
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
}
.newsletter-form .default-btn:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 5px;
  background-color: #fe4c1c;
  z-index: -1;
  -moz-transition: 0.5s;
  transition: 0.5s;
}
.newsletter h6 {
  display: block;
  margin-bottom: 12px;
  color: #fe4c1c;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 700;
}
.newsletter h4 {
  font-size: 40px;
  font-weight: 700;
  /* color: white; */
}
.newsletter p {
  max-width: 600px;
  font-size: 16px;
  font-weight: 500;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.8;
  margin-top: 12px;
  /* color: white; */
}
