/* @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300&family=Poppins:ital,wght@1,200&family=Raleway:wght@200&display=swap'); */

*{

/* color: #ffffff ; */
}
body {
  margin: 0px;
  padding: 0px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;

    /* font-family: 'Nunito', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #000000 !important; */
  /* background-color: #f0f0f0 !important; */
background: linear-gradient(rgba(0,0,0,0.6),rgba(0, 0, 0, 0.6)), url('./assets/backgroundImage10.png') !important;
  /* background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgb(26, 11, 109) 79%, rgb(8, 45, 88)100%) !important; */
 background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
