.saving_account h2{
    color:#fe4d1c;
    }
    .saving_account p {
        
        /* text-align: center; */
        }

/*   Savings Bank Details Card Start    */

.notification {
    display: flex;
    flex-direction: column;
    isolation: isolate;
    position: relative;
    width: 45rem;
    height:auto;
    background: #a2a2a317;
    border-radius: 1rem;
    overflow: hidden;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 16px;
    --gradient: linear-gradient(to bottom, #fe4c1c, #e54013, #b72f0a);
    --color: #fe4c1c;
    padding:9px 0px 15px 20px;
  }
  
  .notification:before {
    position: absolute;
    content: "";
    inset: 0.0625rem;
    border-radius: 0.9375rem;
    background: #bab8b8;
    z-index: 2;
    padding-left:2%;
    

  }
  
  .notification:after {
    position: absolute;
    content: "";
    width: 0.25rem;
    inset: 0.65rem auto 0.65rem 0.5rem;
    border-radius: 0.125rem;
    background: var(--gradient);
    transition: transform 300ms ease;
    z-index: 4;
    margin-left: 10px;
  }
  
  .notification:hover:after {
    transform: translateX(0.15rem)
  }
  
  .notititle {
    color: var(--color);
    padding: 0.45rem 0.25rem 0.4rem 1.25rem;
    font-weight: 500;
    font-size: 1.1rem;
    transition: transform 300ms ease;
    z-index: 5;
  }
  
  .notification:hover .notititle {
    transform: translateX(0.15rem)
  }
  
  .notibody {
    color: #3e3e3e;
    padding: 0 1.25rem;
    transition: transform 300ms ease;
    z-index: 5;
  }
  
  .notification:hover .notibody {
    transform: translateX(0.25rem)
  }
  
  .notiglow,
  .notiborderglow {
    position: absolute;
    width: 20rem;
    height: 22rem;
    transform: translate(-50%, -50%);
    background: radial-gradient(circle closest-side at center, white, transparent);
    opacity: 0;
    transition: opacity 300ms ease;
    margin-left: 20px ;
  }
  
  .notiglow {
    z-index: 3;
  }
  
  .notiborderglow {
    z-index: 1;
  }
  
  .notification:hover .notiglow {
    opacity: 0.1
  }
  
  .notification:hover .notiborderglow {
    opacity: 0.1
  }
  
  .note {
    color: var(--color);
    position: fixed;
    top: 80%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    font-size: 0.9rem;
    width: 75%;
  }


/*   Savings Bank Details Card End    */




/*   Current Bank Details Card Start    */



/*   Current Bank Details Card End    */