@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@1,200&family=Raleway:wght@200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300&family=Poppins:ital,wght@1,200&family=Raleway:wght@200&display=swap");

/*button start*/
.connect_button {
  display: inline;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.8);
  background: rgb(255, 255, 255);
  padding: 10px 40px;
  border-radius: 4px;
  font-weight: normal;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
  /* margin:30px 500px 100px 500px; */
  margin-top: 20px;
}

.glow-button:hover {
  color: white;
  background-color: #fe4c1c;
  box-shadow: 0 5px 15px rgba(165, 165, 165, 0.8);
}
/*button ends here*/

/* cards start */
/* .card_home {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); 
  padding: 100px;
  text-align: center;
  color: #080e32;
  font-weight: 600;
  background-color: #f1f1f1;
}
.card_home:hover {
  background-color: #ddd;
  cursor: pointer;
} */

.text-loop {
  font-size: 24px;
  font-weight: 600;
  display: inline-block;
  animation: textScroll linear infinite;
}

@keyframes textScroll {
  0% {
    transform: translateX(10%);
  }
  100% {
    transform: translateX(-15%);
  }
}
.HeaderMain1{
  z-index: 10;
}

.card_home,
.card_home1 ,
.card_home2{
  padding: 70px;
  text-align: center;
  color: #ffffff;
  font-weight: 600;  

  /* background-color: rgb(26, 26, 26); */
  background-color: #fe4c1c;

  border: 2px solid transparent;
  transition: transform 0.9s; /* Add transition for a smooth effect */
  font-family: "Nunito", sans-serif;
  z-index: 1;
}
.card_home1,
.card_home2 {
  color: rgb(0, 0, 0);
  background-color: #ffffff;
  /* border: 2px solid #fe4c1c; */
}

.card_home:hover{
  /* background-color: #f7d9d1; */
  /* color: #fe4c1c; */
  border-color: #ffffff;
  cursor: pointer;
  transform: scale(0.9); /* Apply a scale transformation on hover */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
.card_home1:hover,
.card_home2:hover {
  /* background-color: #f7d9d1; */
  /* color: #fe4c1c; */
  border-color: #fe4c1c;
  cursor: pointer;
  transform: scale(0.9); /* Apply a scale transformation on hover */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.cards_main {
  border-radius: 50px;
  padding: 0px;
  margin: 0px;
  font-size: 18px;
}
.home_icons{
  font-size: 30px;
}

/*Card Ends*/


/*Main Section start */
.content_main_section {
  margin-top: 50px;
  color: white;
}

.container_main_page {
  /* background: rgb(0, 0, 0); */
  /* background: linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.1)), url('https://img.freepik.com/premium-photo/top-view-workspace-with-laptop-coffee-cup-notebooks-copy-space_67155-6516.jpg?w=900'); */
  /* background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat; */
  /* background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgb(15, 15, 155) 79%, rgb(0, 17, 255) 100%); */
  /* background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(139,47,9,1) 79%, rgba(254,76,28,1) 100%); */

  /* height: 85vh; */
  justify-content: space-evenly;
  /* Spacing between sections */
  align-items: center;
  /* Center vertically */
  /* z-index: 1; */
}
.headline {
  width: 50%;
  min-height: 300px;
  background-size: cover;
  /* display: flex; */
  padding: 60px 20px 20px 70px;
  flex-direction: column;

  flex: 1;
  text-align: start;
}
.image-carousel {
  flex: 1; /* Takes up available space in the container */
  /* Add styling for your image carousel or content here */
}

.h1,
.p {
  /* color: #fff; */
  text-align: start;
  line-height: 1.4;
  width: 400px;
}

.h1 {
  font-size: 2.2rem;
}

.h2 {
  color: #000;
  font-size: 1.3rem;
  text-align: center;
  line-height: 1.4;
  margin-bottom: 10px;
}

/*Main Section ends */

.header-section {
  padding: 80px 0px 80px 0px;
}
.header-section h6 {
  display: block;
  margin-bottom: 12px;
  color: #fe4c1c;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 700;
  text-align: center;
}
.header-section h1 {
  font-size: 38px;
  font-weight: 700;
  text-align: center;
}
.header-section p {
  max-width: 600px;
  font-size: 16px;
  font-weight: 500;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.8;
  margin-top: 12px;
  color: #4b5280;
  text-align: center;
}

.abouthome {
  text-align: left;
  margin-bottom: 50px;
}
.abouthome h6 {
  display: block;
  margin-bottom: 12px;
  color: #fe4c1c;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 700;
  padding-top: 80px;
}
.abouthome h4 {
  font-size: 38px;
  font-weight: 700;
  /* color: white; */
}
.abouthome p {
  /* color: #ffffff; */
  font-size: 16px;
  margin-bottom: 15px;
  line-height: 1.8;
}
.readmore {
  text-align: center;
  display: inline-block;
  -moz-transition: 0.5s;
  transition: 0.5s;
  border-radius: 5px;
  border: none;
  padding: 12px 26px;
  position: relative;
  z-index: 1;
  color: #fff;
  background-color: #fe4c1c;
  font-size: 17px;
  font-weight: 700;
}
.readmore:hover {
  color: #fff;
  background-color: #080e32;
  -moz-transition: 0.5s;
  transition: 0.5s;
  border-radius: 30px;
}

/* serviceshome */

/* Header 4 */
.home-test {
  padding: 100px 0px 80px 0px;
  text-align: left;
}
.home-test h6 {
  display: block;
  margin-bottom: 12px;
  color: #fe4c1c;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 700;
}
.home-test h4 {
  font-size: 40px;
  font-weight: 700;
  /* color: white; */
}
.home-test p {
  /* color: #ffffff; */
  font-size: 16px;
  margin-bottom: 15px;
  line-height: 1.8;
  font-weight: 400;
}
.img-bg-test {
  background-image: url(../../assets/Images/test.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

/* Header 5 team */
.hometeamheader {
  padding: 10px 0px 10px 0px;
  text-align: left;
}

.hometeamheader h6 {
  display: block;
  margin-bottom: 12px;
  color: #fe4c1c;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 700;
  text-align: left;
}
.hometeamheader h4 {
  font-size: 40px;
  font-weight: 700;
  text-align: left;
}
.hometeamheader p {
  max-width: 600px;
  font-size: 16px;
  font-weight: 500;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.8;
  margin-top: 12px;
  color: #4b5280;
  text-align: left;
}

.our-team .pic {
  border-top: 5px solid #fe4c1c;
  border-bottom: 5px solid #fe4c1c;
  border-radius: 50% 50% 50% 0;
  overflow: hidden;
  transition: all 0.5s ease 0s;
}
.our-team:hover .pic {
  border-top-color: #080e32;
  border-bottom-color: #080e32;
  border-radius: 50% 0;
}
.our-team .pic img {
  width: 100%;
  height: auto;
  transition: all 0.5s ease 0s;
}
.our-team .team-content {
  padding: 20px 0;
}
.our-team .title {
  font-size: 18px;
  font-weight: 700;
  color: #080e32;
  text-transform: uppercase;
  margin: 0 0 5px 0;
}
.our-team .post {
  display: block;
  font-size: 16px;
  color: #4b5280;
  text-transform: capitalize;
  margin-bottom: 10px;
}
.our-team .social {
  list-style: none;
  padding: 0;
  margin: 0;
}
.our-team .social li {
  display: inline-block;
}
.our-team .social li a {
  display: block;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 50%;
  border: 1px solid #8f2d56;
  background: #fff;
  font-size: 17px;
  color: #8f2d56;
  text-align: center;
  transition: all 0.5s ease 0s;
}
.our-team:hover .social li a {
  background: #8f2d56;
  color: #fff;
}
@media only screen and (max-width: 990px) {
  .our-team {
    margin-bottom: 30px;
  }
}
