
/* header 6 price */

  .homeprice h6{
    padding-top: 100px;
    display: block;
    margin-bottom: 12px;
    color: #fe4c1c;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 700;
    text-align: center;
  }
  .homeprice h4{
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    color: white;
  }
  .homeprice-box{
    padding: 20px 0px 20px 0px;
    text-align: center;
  }
  .homeprice-box:hover .icon1{
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 15px 35px rgb(0 0 0 / 10%);
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    transform: translateY(-10px);
    transition: 0.5s;
  }
  .homeprice-box:hover .icon2{
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 15px 35px rgb(0 0 0 / 30%);
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    transform: translateY(-10px);
    transition: 0.5s;
  }
  .homeprice-box:hover .icon3{
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 15px 35px rgb(0 0 0 / 10%);
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    transform: translateY(-10px);
    transition: 0.5s;
  }
  .homeprice p{
    max-width: 600px;
    font-size: 16px;
    font-weight: 500;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.8;
    margin-top: 12px;
    color: #ffffff;
    text-align: center;
  }
  .homeprice-box{
  background-color: #fff;
  }
  
  .homeprice-box .homeprice-header h3 {
      margin-bottom: 0;
      font-size: 24px;
      padding: 15px 10px 12px;
      font-weight: 700;
      text-align: center;
  }
  .homeprice-box .homeprice-list ul{
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
    text-align: center;
  }
  .homeprice-box .homeprice-list{
    margin-bottom: 20px;
    padding-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
  }
  .homeprice-box .homeprice-list ul li{
    margin-bottom: 13px;
    color: #4b5280;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
  }
  .homeprice-box .homeprice-price {
    font-size: 20px;
    font-weight: 800;
    text-align: center;
  }
  .homeprice-box .homeprice-price span {
    display: block;
    color: #4b5280;
    margin-top: -3px;
    font-size: 18px;
    margin-top: -2px;
    font-size: 17px;
    font-weight: 600;
    text-align: center;
  }
  