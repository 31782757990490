/* Contact.css */

.contact-page {
  /* padding: 20px;
  text-align: center; */
    /* background-color: whitesmoke !important; */

}

.contact-page h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.contact-page p {
  font-size: 24px;
  color: #000000;
  margin-top: 20px;
}

.map_container iframe {
  margin-top: 75px;
  width: 100%;
  height: 450px;
  box-shadow: 5px 5px 10px rgba(194, 194, 194, 0.3);
}
/* 
.details-container {
  flex: 1;
  text-align: center;
  margin:30px;
  border-radius: 10px;
  background-color: #f4f4f4;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.details-container p {
  font-size: 16px;
  color: #666;
  margin: 5px 0;
}
.logo_contact{
height: 40px;
}




 */
.contact_form-field {
  width: 95%;
  /* background-color: #ffffff; */
  margin-bottom: 10px !important;
 
}


.contact_submit-button {
  background-color: #fe4c1c;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  border: 2px solid transparent;
}

.contact_submit-button:hover {
  background-color: #ffffff;
  color: #fe4c1c;
  border-color: rgb(68, 68, 68);
}

.card_contact {
  width: 100%;
  /* height: 100%; */
  /* display: flex; */
  /* justify-content: center; */
  
  /* background: rgb(255, 255, 255); */
  /* background-color: whitesmoke !important; */



  /* box-shadow: 7px 5px 10px rgba(0, 0, 0, 0.333); */
  border-radius: 10px;
}

.imge {
  height: 70px;
  /* background-color:#929292 ; */
}

.imge .Usericon {
  /* background-color:#f5f5f5 ; */
  transform: translateX(6px) translateY(10px);
  width: 58px;
  height: 58px;
  border-radius: 10px;
}

.imge .UserName {
  /* background-color: #414141; */
  width: 300px;
  height: 20px;
  border-width: 10px;
  border-radius: 5px;
  border-color: #262626;
  color: #ffffff;
  font-size: 15px;
  /* font-weight: bold; */
  transform: translateX(75px) translateY(-65px);
}

.imge .Id {
  /* background-color: #414141; */
  width: 350px;
  height: 15px;
  border-width: 10px;
  border-radius: 5px;
  border-color: #262626;
  color: #ffffff;
  font-size: 15px;
  /* font-weight: bold; */
  transform: translateX(75px) translateY(-80px);
}

.Description {
  /* border-color: #141414; */
  /* background-color: #ffffff; */
  /* background-color: whitesmoke !important; */

  transform: translate(5px, 5px);
  width: 98%;
  height: 100%;
  border-radius: 5px;
}

/*______Social Media______*/
.social-media {
  height: 80px;
  /* background: #414141; */
}
.social-icon:hover {

  color: #252323;
}

.social-media ul li {
  list-style: none;  
}
.social-icon{
  color: #fe4c1c;
  font-size: 20px;
}


/* Add media queries for responsive design */
@media (max-width: 768px) {
  .contact-page {
    padding: 10px;
  }
  .google-maps {
    margin-top: 10px;
  }
}
