.about_container{
   display: flex;
   justify-content: center;
   
}
.about_intro{
    background-color: rgb(66, 66, 66);
    width: 90%;
    padding: 30px;
    margin-top: 40px;
    color: white;
}