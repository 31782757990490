@import url("https://fonts.googleapis.com/css?family=Raleway:400,700");

/* * {
	box-sizing: border-box;
	margin: 0;
	padding: 0;	
	font-family: Raleway, sans-serif;
} */

.Screen1 {
  background: linear-gradient(90deg, #343434, #232323);
  position: relative;
  height: 500px;
  width: 500px;
  /* box-shadow: 0px  0px 24px #ffffff; */
  margin-left: 5%;
  /* opacity: 0.73; */
}
.heading_welcome {
  text-align: center;
  margin-top: 35px;
  color: #ffffff;
}
.image_logo {
  height: 380px;
  margin: -15px 20px -60px 12%;
  opacity: 1;

  border-radius: 100%;
  transform: translateY(-10px) scale(0.95); /* Initial state */
  transition: transform 1s;
}
.image_logo:hover {
  transform: translateY(-10px) scale(1.02); /* Adjust the scale factor as needed */
}

.form_box {
  box-shadow: 0px 0px 24px #757474;
}
.login_container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 95vh;
}

.login_screen {
  background: linear-gradient(90deg, #101011, #2a2a2d);
  position: relative;
  height: 500px;
  width: 360px;
  /* box-shadow: 0px 0px 24px #ffffff; */
  margin-left: 5%;
  opacity: 0.75;
}

.screen__content {
  z-index: 1;
  position: relative;
  height: 100%;
}

.screen__background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  -webkit-clip-path: inset(0 0 0 0);
  clip-path: inset(0 0 0 0);
}

.screen__background__shape {
  transform: rotate(45deg);
  position: absolute;
}

.screen__background__shape1 {
  height: 520px;
  width: 520px;
  /* background: #434343; */
  top: -50px;
  right: 120px;
  border-radius: 0 72px 0 0;
}

.screen__background__shape2 {
  height: 220px;
  width: 220px;
  background: #000000;
  top: -172px;
  right: 0;
  border-radius: 32px;
}

.screen__background__shape3 {
  height: 540px;
  width: 190px;
  background: linear-gradient(270deg, #0b0b0b, #000000);
  top: -24px;
  right: 0;
  border-radius: 32px;
}

.image {
  margin-right: 300px;
}

.login {
  width: 380px;
  padding: 30px;
  padding-top: 150px;
}

.login__field {
  padding: 30px 0px;
  position: relative;
}

/* .login__icon {
	position: absolute;
	top: 30px;
	color: #7875B5;
} */

.login__input {
  border: none;
  font-size: 14px;
  border-bottom: 2px solid #a7a7a8;
  /* background: rgba(255, 0, 0, 0); */
  padding: 10px 10px 10px 10px;
  /* padding-left: ; */
  font-weight: 700;
  width: 140%;
  transition: 0.2s;
}

.login__input:active,
.login__input:focus,
.login__input:hover {
  outline: none;
  border-bottom-color: #fe4c1c;
}

.login__submit {
  background: #fff;
  font-size: 14px;
  margin-top: 30px;
  padding: 16px 20px;
  border-radius: 26px;
  border: 2px solid #d4d3e8;
  text-transform: uppercase;
  font-weight: 700;
  display: flex;
  align-items: center;
  width: 100%;
  color: #000000;
  box-shadow: 0px 2px 2px #5d5d5d;
  cursor: pointer;
  transition: 0.2s;
}

.login__submit:active,
.login__submit:focus,
.login__submit:hover {
  border-color: #fe4c1c;
  outline: none;
}

.social-login {
  position: absolute;
  /* height: 85px; */
  width: auto;
  text-align: start;
  /* bottom: 0px; */
  right: 30px;
  color: #fff;
  font-size: 18px;
}
.social-login:hover {
  color: #fe4c1c;
  font-size: 18px;
}
.heading_name {
  text-align: center;
  margin-top: 30px;
  color: rgb(255, 255, 255);
}

.button__text {
  color: #0b0b0b;
}
