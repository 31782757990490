.services-card {
    padding: 40px 20px;
    text-align: center;
    background-color: #ffffff;
    padding: 50px 25px;
    border-radius: 5px;
  }
  .services-card:hover {
    border-radius: 0;
    background-color: #f9f9f9;
    box-shadow: 0 15px 35px rgb(0 0 0 / 30%);
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    transform: translateY(-10px);
    transition: 0.5s;
  }