.nav_items_dropdown {
  color: black;
}
.nav_items_dropdown:hover {
  color: #fe4c1c;
}

.bg-False {
  /* backdrop-filter: blur(10px); */
}
.bg-True {
  backdrop-filter: blur(10px);
}

.bg-False .nav_items_main {
  /* color: #ffffff; */
  color: #fe4c1c;

  font-weight: 600;
  font-size: 16px;
}
.bg-False .nav_items_main:hover {
  color: #ffffff;
}

.bg-True .nav_items_main {
  color: #fe4c1c;
  font-weight: 600;
  font-size: 16px;
}
.bg-True .nav_items_main:hover {
  color: #ffffff;
}

.emp_login_btn {
  border-color: "white";
  color: "white";
}

@media only screen and (max-width: 720px) {
  h1 {
    font-size: 1.9rem;
  }

  h2 {
    font-size: 1rem;
  }

  p {
    font-size: 0.8rem;
  }
}
